<template>
  <div>
    <v-card>
      <show-model :text="items"></show-model>

      <v-card-text>
        <ValidationObserver
          ref="observer"
          v-slot="{ valid }"
        >
          <v-row>
            <v-col cols="4">
              <render-input
                v-model="model.format"
                :input="fieldConfig.format"
              ></render-input>
            </v-col>
          </v-row>
          <v-row v-if="model.format">
            <v-col cols="2">
              <uploadFile-button
                @changedFile="handleFile1"
                icon="cloud_upload"
                text="File PGP"
                accept="excel"
                :multiple="false"
                class="d-inline-block"
              ></uploadFile-button>
            </v-col>
            <v-col>
              <div class="ml-2 mt-2">{{ file1?.name }}</div>
            </v-col>
          </v-row>
          <v-row v-if="model.format?.id == 2">
            <v-col cols="2">
              <uploadFile-button
                @changedFile="handleFile2"
                icon="cloud_upload"
                text="File AEON"
                accept="excel"
                :multiple="false"
                class="d-inline-block"
              ></uploadFile-button>

            </v-col>
            <v-col>
              <div class="ml-2 mt-2">{{ file2?.name }}</div>
            </v-col>
          </v-row>

          <div class="mt-5"></div>

          <v-btn
            @click="testupload()"
            class="primary"
            :disabled="!valid"
            v-if="model.format?.id == 1 && file1 && loading == false"
          >Generate</v-btn>

          <v-btn
            @click="testupload()"
            class="primary"
            :disabled="!valid"
            v-if="model.format?.id == 2 && file1 && file2 && loading == false"
          >Generate</v-btn>

          <v-row v-if="loading">
            <v-col offset-md="4">
              <span>Loading...</span>
            </v-col>
          </v-row>

          <!-- <v-btn
            @click="testdownload02()"
            class="primary"
          >Test download 02</v-btn> -->
        </ValidationObserver>

      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import { InputType } from "../js/constants";

import uploadFileButton from "./../components/custom/uploadfile-button";

export default {
  components: {
    uploadFileButton,
  },
  data() {
    return {
      model: {
        format: null,
      },
      file1: null,
      file2: null,
      loading: false,
    };
  },
  methods: {
    testupload() {
      let url = "";

      let payload = new FormData();
      if (this.model.format?.id == 1) {
        payload.append("file", this.file1);
        url = "/report/remain/format1";
      } else if (this.model.format?.id == 2) {
        payload.append("file", this.file1);
        payload.append("file", this.file2);
        url = "/report/remain/format2";
      } else {
        return;
      }
      this.loading = true;

      this.$http
        .uploadFile(url, payload, { responseType: "arraybuffer" })
        .then((res) => {
          this.tiktik(res);
          this.showAlertSuccess("File has been uploaded successfully");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleFile1(files) {
      if (!(files && files.length > 0)) {
        return;
      }
      this.file1 = files[0];
    },
    handleFile2(files) {
      if (!(files && files.length > 0)) {
        return;
      }
      this.file2 = files[0];
    },
    tiktik(res) {
      let filename = "Remain.xlsx";
      let blob = new File([res], filename, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      var URL = window.URL || window.webkitURL;
      var downloadUrl = URL.createObjectURL(blob);

      if (filename) {
        // use HTML5 a[download] attribute to specify filename
        var a = document.createElement("a");
        // safari doesn't support this yet
        if (typeof a.download === "undefined") {
          window.location = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      } else {
        window.location = downloadUrl;
      }

      setTimeout(function () {
        URL.revokeObjectURL(downloadUrl);
      }, 100); // cleanup
    },
  },
  computed: {
    fieldConfig() {
      return {
        format: {
          name: "format",
          text: "Format",
          type: InputType.dropdown,
          rule: {
            required: true,
          },
          data: {
            promise: Promise.resolve({
              items: [
                { id: 1, text: "Format 1" },
                { id: 2, text: "Format 2" },
              ],
            }),
          },
        },
      };
    },
  },
};
</script>

<style>
.v-btn {
  text-transform: none !important;
}
</style>